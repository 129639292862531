import { useSession } from "next-auth/react";
import { Router, useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import { apiInstance } from "../lib/url";
import { insertCompany, insertLocation } from "../redux/reducers/company";
import { UserIntialState } from "../redux/reducers/user";
import { RootState } from "../redux/store";
import { LinearProgress } from "@mui/material";
import { companyType } from "../lib/types";

interface propType {
  children: React.ReactNode;
}

const GetInfo = ({ children }: propType) => {
  const router = useRouter();
  const { location_id } = router.query;
  const path = router.asPath;
  const [isLoading, setIsLoading] = useState(true);
  const [isRouteLoad, setIsRouteLoad] = useState(false);
  const user = useSelector((state: RootState) => state.user.id);
  const dispatch = useDispatch();
  const { data: session, status }: any = useSession();
  const { id } = useSelector<RootState>(
    (state) => state.user
  ) as UserIntialState;

  useEffect(() => {
    const handleRouteChange = () => {
      setIsRouteLoad(true);
      return;
    };

    const handleRouteComplete = () => {
      setIsRouteLoad(false);

      return;
    };

    router.events.on("routeChangeStart", handleRouteChange);
    router.events.on("routeChangeComplete", handleRouteComplete);
    router.events.on("routeChangeError", handleRouteComplete);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, []);

  useEffect(() => {
    getLocation();
  }, [location_id, id]);

  useEffect(() => {
    if ((session?.user?.company || user) && status === "authenticated") {
      getCompanyInfo();
    } else {
      setIsLoading(false);
    }
  }, [session?.user?.company, user, status]);

  const getCompanyInfo = async () => {
    try {
      const { data }: { data: companyType } = await apiInstance.get(
        `company/getCompany?id=${session.user.company || user}`
      );

      if (data) {
        // router.push(path, path, { locale: data.language });
        dispatch(insertCompany(data));
        await getLocation();
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const getLocation = async () => {
    if (path.includes("location_id")) {
      if (location_id && id) {
        return apiInstance
          .get(`locations/getLocation?id=${location_id}`)
          .then(async (res) => {
            dispatch(insertLocation(res.data));

            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
          });
      }
    } else {
      setIsLoading(false);
    }

    return;
  };

  if (isLoading || (path.includes("/signin") && isRouteLoad)) {
    return <Loader />;
  }

  return (
    <>
      {isRouteLoad && (
        <div className="absolute top-0 left-0 w-full z-[9999]">
          <LinearProgress
            sx={{
              backgroundColor: `rgba(255, 99, 57,0.4)`,
              "& .MuiLinearProgress-bar": {
                backgroundColor: `rgb(255, 99, 57)`,
              },
            }}
          />
        </div>
      )}

      {children}
    </>
  );
};

export default GetInfo;
