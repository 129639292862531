import { services as Service } from "@prisma/client";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface InitialStateType {
  hotelCompanyInfo: null;
}

const initialState = {
  hotelCompanyInfo: null,
};

const hotelCompanyInfo = createSlice({
  name: "hotelCompanyInfo",
  initialState,
  reducers: {
    insertHotelCompanyInfo: (state: InitialStateType, action: any) => {
      state.hotelCompanyInfo = action.payload;
    },
  },
});

export const { insertHotelCompanyInfo } = hotelCompanyInfo.actions;

export const getHotelCompanyInfo = (state: RootState) =>
  state.hotelCompanyInfo.hotelCompanyInfo;

export default hotelCompanyInfo.reducer;
