"use client";
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useRouter } from "next/router";

const Loader = () => {
  const [animationData, setAnimationData] = useState<any>(null);
  const { asPath } = useRouter();

  const loadAnimationData = async () => {
    let animation;
    if (asPath.startsWith("/order") || asPath.startsWith("/hotel")) {
      animation = await import("./../public/lottie/Customer Facing.json");
    } else {
      animation = await import("./../public/lottie/Admin_Staff.json");
    }
    setAnimationData(animation?.default);
  };

  useEffect(() => {
    loadAnimationData();
  }, [asPath]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="fixed top-0 left-0 w-screen bg-grey h-screen flex justify-center items-center z-[999]">
      {animationData && (
        <Lottie options={defaultOptions} height={300} width={300} />
      )}
    </div>
  );
};

export default Loader;
