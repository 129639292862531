import { createSlice } from "@reduxjs/toolkit";
import { User } from "./../../types";

interface InitialStateType {
  customers: User[] | null;
  showCustomers: User[] | null;
}

const initialState: InitialStateType = {
  customers: null,
  showCustomers: null,
};

const CustomerSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    storeCustomers: (state, action) => {
      state.customers = action.payload;
    },
    storeShowCustomers: (state, action) => {
      state.showCustomers = action.payload;
    },
    editCustomer: (state, action) => {
      const { id } = action.payload;
      state.customers =
        state.customers?.map((i) =>
          i.id === id ? { id, ...action.payload } : i
        ) || null;
      state.showCustomers =
        state.showCustomers?.map((i) =>
          i.id === id ? { id, ...action.payload } : i
        ) || null;
    },
  },
});
export const { storeCustomers, storeShowCustomers, editCustomer } =
  CustomerSlice.actions;
export default CustomerSlice.reducer;
