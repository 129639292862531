import { createSlice } from "@reduxjs/toolkit";

export interface SideBarType {
  isOpen: boolean;
  SocketUpdate: string;
}

const initialState: SideBarType = {
  isOpen: false,
  SocketUpdate: "",
};

const sidebar = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    openSideBar: (state) => {
      state.isOpen = true;
    },
    closeSideBar: (state) => {
      state.isOpen = false;
    },
    setSocketUpdate: (state, action: any) => {
      state.SocketUpdate = action.payload;
    },
  },
});

export const { setSocketUpdate, openSideBar, closeSideBar } = sidebar.actions;

export const getSocketUpdate = (state: any) => state.sidebar.SocketUpdate;

export default sidebar.reducer;
