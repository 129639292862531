import { invoiceSettings, printerType } from "./../../lib/types";
import { createSlice } from "@reduxjs/toolkit";
import { companyType, locationType } from "../../lib/types";

export interface companyInitialType {
  name: string;
  choosenLocation: locationType | null;
  companyInfo: companyType | any;
  defaultLocation: locationType | null;
  stripe_subscription: any | null;
  stripe_metadata: any | null;
  selectedContacts: any | null;
  printer: printerType | null;
}
//

const initialState: companyInitialType = {
  name: "",
  choosenLocation: null,
  companyInfo: null,
  defaultLocation: null,
  stripe_subscription: null,
  stripe_metadata: null,
  selectedContacts: null,
  printer: null,
};

const company = createSlice({
  name: "company",
  initialState,
  reducers: {
    insertLocation: (state, action) => {
      state.choosenLocation = action.payload;
    },
    emptyLocation: (state) => {
      state.choosenLocation = null;
    },
    emptyDefaultLocation: (state) => {
      state.defaultLocation = null;
    },
    updateInvoiceSetting: (state, action) => {
      state.companyInfo.invoice_settings = action.payload;
    },
    emptyCompany: (state) => {
      state.companyInfo = null;
    },
    insertCompany: (state, action) => {
      state.companyInfo = action.payload;
    },
    insertCompanyColors: (state, action) => {
      state.companyInfo.company_color = action.payload;
    },
    insertDefaultLocation: (state, action) => {
      state.defaultLocation = action.payload;
    },
    insertStripeSubscription: (state, action) => {
      state.stripe_subscription = action.payload;
    },
    insertCurrentStripePlanMetada: (state, action) => {
      state.stripe_metadata = action.payload;
    },
    updateStripeCustomerId: (state, action) => {
      state.companyInfo.stripe_customer_id = action.payload;
    },
    updateCompanySelectedLanguages: (state, action) => {
      state.companyInfo.selected_languages = action.payload;
    },
    updateCompanySlectedContacts: (state, action) => {
      state.selectedContacts = action.payload;
    },
  },
});

export const {
  insertLocation,
  insertCompany,
  insertCompanyColors,
  emptyLocation,
  emptyCompany,
  updateInvoiceSetting,
  insertDefaultLocation,
  insertStripeSubscription,
  updateStripeCustomerId,
  insertCurrentStripePlanMetada,
  updateCompanySelectedLanguages,
  updateCompanySlectedContacts,
} = company.actions;

export const getCompanyData = (state: any) => state.company.companyInfo;

export default company.reducer;
