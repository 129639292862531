import { services as Service } from "@prisma/client";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface initialStateType {
  selectedStatus: string;
  selectedA11yProps: string;
}

const initialState: initialStateType = {
  selectedStatus: "All",
  selectedA11yProps: "",
};

const selectedStatusSlice = createSlice({
  name: "selectedStatusSlice",
  initialState,
  reducers: {
    insertSelectedStatus: (state: initialStateType, action: any) => {
      state.selectedStatus = action.payload;
    },
    insertA11yProps: (state: initialStateType, action: any) => {
      state.selectedA11yProps = action.payload;
    },
  },
});

export const { insertSelectedStatus, insertA11yProps } =
  selectedStatusSlice.actions;

export const selectedStatusValue: any = (state: RootState) =>
  state.selectedStatusSlice.selectedStatus;
export const A11yPropsValue = (state: RootState) =>
  state.selectedStatusSlice.selectedA11yProps;

export default selectedStatusSlice.reducer;
